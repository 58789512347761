import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, vShow as _vShow, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d4a6fce0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "md:h-full md:overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_snap_logo = _resolveComponent("snap-logo")!
  const _component_PageHeaderNav = _resolveComponent("PageHeaderNav")!
  const _component_snap_logo_header_end = _resolveComponent("snap-logo-header-end")!
  const _component_snap_global_header = _resolveComponent("snap-global-header")!
  const _component_snap_main_navigation = _resolveComponent("snap-main-navigation")!
  const _component_SelfIdentifyDialog = _resolveComponent("SelfIdentifyDialog")!
  const _component_ZendeskHelpWidget = _resolveComponent("ZendeskHelpWidget")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.wrapper), {
      class: "md:h-screen",
      onSnapImpersonationModeChanged: _ctx.onImpersonationModeChange
    }, {
      default: _withCtx(() => [
        _createVNode(_component_snap_global_header, {
          theme: _ctx.theme,
          impersonationMode: _ctx.isImpersonated
        }, {
          default: _withCtx(() => [
            _createVNode(_component_snap_logo, {
              product: "snap-logo-mobile",
              white: "true",
              class: "cursor-pointer",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$router.push({ name: 'home' })), ["prevent"]))
            }),
            _createVNode(_component_snap_logo_header_end, null, {
              default: _withCtx(() => [
                _createVNode(_component_PageHeaderNav, { color: "#fff" })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["theme", "impersonationMode"]),
        _createElementVNode("main", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _createVNode(_component_snap_main_navigation, {
          theme: _ctx.theme,
          ".navigationOptionsInput": _ctx.sidebar,
          impersonationMode: _ctx.isImpersonated,
          onNavigationupdate: _ctx.navigate,
          onSnapToolSwitcherUpdate: _ctx.switchContext
        }, null, 40, ["theme", ".navigationOptionsInput", "impersonationMode", "onNavigationupdate", "onSnapToolSwitcherUpdate"])
      ]),
      _: 3
    }, 40, ["onSnapImpersonationModeChanged"])), [
      [_vShow, _ctx.isAuthorized]
    ]),
    _createVNode(_component_SelfIdentifyDialog),
    _createVNode(_component_ZendeskHelpWidget)
  ], 64))
}