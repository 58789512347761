import {
  AffiliationTitle,
  Leader,
  Org,
  Product,
  ProgramWithTitle,
  RelationshipTitles,
  TeamTitle,
  TeamWithTitle,
  UserAffiliation,
} from "@/types/graphql";

export type RelationTitle = AffiliationTitle | TeamTitle;

export type CombinedAffiliation = {
  org: Org;
  affiliations: Array<UserAffiliation>;
  teams: Array<TeamWithTitle>;
  programs: Array<ProgramWithTitle>;
  associations?: { product: Product }[];
  impliedAffiliations?: Array<UserAffiliation>;
};

export type OrgAffiliationViewData = {
  governingOrg: Org;
  org: Org;
  titles: Array<Leader>;
  affiliations: Array<{
    title: string;
    org: Org;
  }>;
  impliedAffiliations: Array<{
    title: string;
    org: Org;
  }>;
  programs: Array<{
    titles: {
      title: Array<RelationshipTitles>;
      team: Org;
    };
  }>;
  teams: Array<{
    titles: {
      title: Array<RelationshipTitles>;
      team: Org;
    };
  }>;
  associations?: { product: Product; org: Org }[];
};

export type Relation = {
  title?: RelationTitle;
  description?: string;
  teamIds?: Array<string>;
  programId?: string;
};

export type ProgramLeader = {
  program: Org;
  title: Leader;
};

export type TeamLeader = {
  team: Org;
  title: Leader;
};

export type NonBoosterTeamTitle = Exclude<
  TeamTitle,
  | TeamTitle.BoosterClubLeader
  | TeamTitle.BoosterClubMember
  | TeamTitle.ClubSportsAdministrator
  | TeamTitle.ClubSportsDirector
>;

export const NON_BOOSTER_TEAM_TITLES = Object.values(TeamTitle).filter(
  (title) =>
    ![
      TeamTitle.BoosterClubLeader,
      TeamTitle.BoosterClubMember,
      TeamTitle.ClubSportsAdministrator,
      TeamTitle.ClubSportsDirector,
    ].includes(title)
) as NonBoosterTeamTitle[];

export const isNonBoosterTeamTitle = (title?: RelationTitle | null): boolean =>
  !!title && NON_BOOSTER_TEAM_TITLES.includes(title as NonBoosterTeamTitle);

export const isTeamTitle = (title?: RelationTitle | null): boolean =>
  !!title && Object.values(TeamTitle).includes(title as TeamTitle);
