import { apolloClient } from "@/providers/apollo.provider";

import MessagesChannelUpdateMutation from "@/graphql/messagesChannelUpdate.mutation.graphql";
import MessagesChannelRemoveMembers from "@/graphql/messagesChannelRemoveMembers.mutation.graphql";
import MyModeratedChannels from "@/graphql/messagesMyModeratedChannels.query.graphql";
import MessagesChannelCreateMutation from "@/graphql/messagesChannelCreate.mutation.graphql";
import MessagesChannelInviteMembersMutation from "@/graphql/messagesChannelInviteMembers.mutation.graphql";
import MessagesChannelInviteResendMutation from "@/graphql/messagesChannelInviteResend.mutation.graphql";

import {
  ChatChannelCreateResult,
  ChatChannelUpdateResult,
  MessagesChannelRemoveMembersResult,
} from "@/types/graphql";

export const createChannel = async (
  name: string,
  type: string,
  assignmentId: string,
  userIds: string[],
  isReadOnly?: boolean,
  description?: string
): Promise<ChatChannelCreateResult> => {
  return apolloClient
    .mutate({
      mutation: MessagesChannelCreateMutation,
      variables: { name, type, assignmentId, userIds, isReadOnly, description },
    })
    .then(({ data }) => data.messagesChannelCreate);
};

export const updateChannel = async (
  id: string,
  channel: {
    name?: string;
    description?: string;
    status?: string;
    isReadOnly?: boolean;
  }
): Promise<ChatChannelUpdateResult> => {
  return apolloClient
    .mutate({
      mutation: MessagesChannelUpdateMutation,
      variables: { id, channel },
    })
    .then(({ data }) => data.messagesChannelUpdate);
};

export const removeChannelMembers = async (
  id: string,
  userIds: [string]
): Promise<MessagesChannelRemoveMembersResult> => {
  return apolloClient
    .mutate({
      mutation: MessagesChannelRemoveMembers,
      variables: { id, userIds },
    })
    .then(({ data }) => data.messagesChannelRemoveMembers);
};

export const myModeratedChannelsCount = async (): Promise<number> => {
  return apolloClient
    .query({
      query: MyModeratedChannels,
    })
    .then(({ data }) => {
      if (data.messagesMyModeratedChannels?.message) {
        throw new Error(data.messagesMyModeratedChannels.message);
      }
      return data.messagesMyModeratedChannels.orgsCount;
    })
    .catch((e) => {
      console.error(e.message);
      return 0;
    });
};

export const messagesChannelInviteMembers = async (
  channelId: string,
  contacts: {
    method: string;
    contact: string;
    role: string;
    firstName?: string;
    lastName?: string;
  }[],
  users: { id: string; role: string }[] = []
) => {
  return apolloClient
    .mutate({
      mutation: MessagesChannelInviteMembersMutation,
      variables: { channelId, contacts, users },
    })
    .then(({ data }) => {
      if (data.messagesChannelInviteMembers?.message) {
        throw new Error(data.messagesChannelInviteMembers.message);
      }
      return data.messagesChannelInviteMembers;
    })
    .catch((e) => {
      console.error(e.message);
      throw new Error(e);
    });
};

export const messagesChannelInviteResend = async (
  channelId: string,
  inviteId: string
) => {
  return apolloClient
    .mutate({
      mutation: MessagesChannelInviteResendMutation,
      variables: { messagesChannelInviteResendId: channelId, inviteId },
    })
    .then(({ data }) => {
      if (data.messagesChannelInviteResend?.message) {
        throw new Error(data.messagesChannelInviteResend.message);
      }
      return data.messagesChannelInviteResend;
    })
    .catch((e) => {
      console.error(e.message);
      throw new Error(e);
    });
};
